<template>
  <div :class="rootClasses">
    <div v-if="showHeaderSection" class="bitts-table__cta-wrapper">
      <div class="bitts-table__cta-left">
        <slot name="cta-left">
          <div
            :class="shrinkTitle ? null : 'flex-1'"
            class="text-neutral-900 text-lg"
          >
            {{ title }}
          </div>
        </slot>
      </div>
      <div class="bitts-table__cta-right">
        <slot name="cta-right" />
      </div>
    </div>
    <div class="bitts-table__ag-grid-container">
      <AgGridVue
        :column-defs="columns"
        :default-col-def="defaultColumnDef"
        :row-data="rows"
        :enable-cell-text-selection="enableCellTextSelection"
        :pagination="pagination"
        :suppress-pagination-panel="true"
        :pagination-page-size="paginationPageSize"
        :suppress-cell-focus="true"
        :suppress-row-hover-highlight="true"
        :suppress-click-edit="true"
        :sorting-order="sortOrder"
        :row-selection="rowSelection"
        :row-height="rowHeight"
        :suppress-browser-resize-observer="true"
        :suppress-row-virtualisation="suppressRowVirtualisation"
        :suppress-column-virtualisation="suppressColumnVirtualisation"
        :row-multi-select-with-click="rowMultiSelectWithClick"
        :suppress-row-click-selection="suppressRowClickSelection"
        :components="components"
        :context="context"
        class="ag-theme-alpine"
        dom-layout="autoHeight"
        v-on="extraHandlers"
        @row-clicked="onRowClicked"
        @first-data-rendered="onDataRendered"
        @grid-size-changed="onDataRendered"
        @grid-ready="onGridReady"
        @pagination-changed="onPaginationChanged"
      />
    </div>
    <div v-if="showPagination" class="bitts-table__pagination-container">
      <div
        v-if="rows.length"
        :class="[!showPaginationResultsText ? 'opacity-0' : '']"
        class="flex-1 text-sm text-neutral-500 hidden sm:block"
      >
        {{ paginationResultsText }}
      </div>
      <BittsPaginator
        v-if="showPagination && paginationData?.page"
        :page="Number(paginationData.page)"
        :last-page="paginationData.last_page"
        @page-click="onPageClick"
      />
      <BittsPaginator
        v-if="showPagination && !paginationData?.page"
        :page="Number(page) || 1"
        :last-page="lastPage || 1"
        @page-click="onPageClick"
      />
    </div>
    <div class="h-24 invisible" v-if="!showPagination" />
  </div>
</template>

<script setup lang="ts">
import { Nullable, Pagination } from '@crossbeam/types';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  RowClickedEvent,
  RowNode,
} from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue3';
import {
  Slots,
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  useSlots,
  watch,
} from 'vue';

import BittsPaginator from '../BittsPaginator/BittsPaginator.vue';

import CustomColumnHeader from './CustomColumnHeader.vue';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

type PassedContext = Record<string, unknown>;

const {
  bordered = false,
  columns = [],
  compressColumns = false,
  defaultColumnDef = {},
  enableCellTextSelection = false,
  enforceResponsiveSizing = false,
  hidePagination = false,
  onRowSelected = null,
  pagination = true,
  paginationData = null,
  paginationPageSize = 20,
  passedContext = {},
  resultsText = 'results',
  rowHeight = null,
  rowMultiSelectWithClick = null,
  rowSelection = null,
  rows = [],
  searchQuery = '',
  showPaginationResultsText = true,
  shrinkTitle = false,
  sortOrder = ['asc', 'desc'],
  suppressRowClickSelection = null,
  title = '',
  useSearch = false,
} = defineProps<{
  bordered?: boolean;
  columns?: ColDef[];
  compressColumns?: boolean;
  defaultColumnDef?: Record<string, string>;
  enableCellTextSelection?: boolean;
  enforceResponsiveSizing?: boolean;
  hidePagination?: boolean;
  onRowSelected?: Nullable<(e: RowNode) => void>;
  pagination?: boolean;
  paginationData?: Pagination;
  paginationPageSize?: number;
  passedContext?: PassedContext;
  resultsText?: string;
  rowHeight?: Nullable<number>;
  rowMultiSelectWithClick?: Nullable<boolean>;
  rowSelection?: Nullable<string>;
  rows?: Record<string, unknown>[];
  searchQuery?: string;
  showPaginationResultsText?: boolean;
  shrinkTitle?: boolean;
  sortOrder?: string[];
  suppressRowClickSelection?: Nullable<boolean>;
  title?: string;
  useSearch?: boolean;
}>();

const emit = defineEmits<{
  (e: 'pageClick', page: number): void;
  (e: 'grid-ready', params: GridReadyEvent): void;
  (e: 'columns-compressed'): void;
  (e: 'row-clicked', data: RowClickedEvent['data']): void;
}>();

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const page = ref<number>(1);
const lastPage = ref<Nullable<number>>(null);
const showPagination = computed(() => pagination && !hidePagination);
const paginationRows = computed(() => {
  if (searchQuery) return paginationData?.total_count || rows.length;
  return displayedRowCount.value;
});
const pageStartIndex = computed(() => {
  if (paginationData?.page) {
    return 1 + paginationPageSize * (paginationData.page - 1);
  }
  if (!gridApi.value?.paginationGetCurrentPage) return;
  return paginationPageSize * (page.value - 1) + 1;
});
const pageEndIndex = computed(() => {
  if (paginationData?.page) {
    return Math.min(
      paginationData.page * paginationPageSize,
      paginationRows.value,
    );
  }
  if (!gridApi.value?.paginationGetCurrentPage) return;
  return Math.min(page.value * paginationPageSize, paginationRows.value);
});
const paginationResultsText = computed(() => {
  if (!pageStartIndex.value || !pageEndIndex.value)
    return `Showing 0 ${resultsText}`;
  return `Showing ${pageStartIndex.value} - ${pageEndIndex.value} of ${paginationRows.value} ${resultsText}`;
});
function onPageClick(pageNumber: number) {
  gridApi.value?.paginationGoToPage(pageNumber);
  page.value = pageNumber;
  emit('pageClick', pageNumber);
}
function onPaginationChanged() {
  if (!gridApi.value?.paginationGetCurrentPage) return;
  lastPage.value = gridApi.value?.paginationGetTotalPages();
}

const displayedRowCount = ref<number>(0);

const gridApi = ref<Nullable<GridApi>>(null);
const gridColumnApi = ref<Nullable<ColumnApi>>(null);

const suppressRowVirtualisation = ref(false);
const suppressColumnVirtualisation = ref(false);

const slots: Slots = useSlots() as Slots;
const showHeaderSection = computed(() => title || !!slots['cta-left']);

type Component = ReturnType<typeof defineComponent>;
const context = ref<Nullable<PassedContext>>(null);
const components = ref<Nullable<Record<string, Component>>>(null);
onBeforeMount(() => {
  // We need to suppress row virtualisation when testing. If we don't, all rows won't render as expected
  // See https://blog.ag-grid.com/testing-ag-grid-react-jest-enzyme/#querying-dom
  suppressRowVirtualisation.value = suppressColumnVirtualisation.value =
    import.meta.env.MODE === 'test';
  components.value = { agColumnHeader: CustomColumnHeader };
  context.value = passedContext;
});

const rootClasses = computed(() => {
  return {
    'bitts-table': true,
    bordered,
    pagination: showPagination.value,
    'with-header': showHeaderSection.value,
  };
});
const extraHandlers = computed(() => {
  if (!onRowSelected) return {};
  return { 'row-selected': onRowSelected };
});

function onGridReady(params: GridReadyEvent) {
  gridApi.value = params.api;
  gridColumnApi.value = params.columnApi;

  lastPage.value = gridApi.value?.paginationGetTotalPages() || null;
  displayedRowCount.value = gridApi.value?.getDisplayedRowCount() || 0;
  emit('grid-ready', params);
}
function buildResponsiveGrid(params: FirstDataRenderedEvent) {
  params.columnApi.autoSizeAllColumns();

  const flexColumn = params.columnApi
    .getAllColumns()
    ?.find((col) => col.getColDef().flex);

  if (flexColumn) {
    const gridPixelRange = params.api.getHorizontalPixelRange();
    const totalColumnWidths =
      params.columnApi
        .getAllColumns()
        ?.reduce((sum: number, col) => sum + (col.getActualWidth() || 0), 0) ||
      0;

    // If there's extra space, let the flex column fill it
    if (gridPixelRange.right > totalColumnWidths) {
      params.api.sizeColumnsToFit();
    } else {
      // If there's no extra space, keep the natural widths and enable scrolling
      params.columnApi.autoSizeColumn(flexColumn.getId());
    }
  }
}

function onDataRendered(params: FirstDataRenderedEvent) {
  if (enforceResponsiveSizing) {
    buildResponsiveGrid(params);
    return;
  }
  if (compressColumns) {
    params.api.sizeColumnsToFit();
    emit('columns-compressed');
  } else {
    const columns = params.columnApi.getColumns();
    const numberOfColumns = columns?.length || 0;
    if (numberOfColumns > 0 && columns?.[0]) {
      const columnWidth = columns[0].getActualWidth();
      const gridPixelRange = params.api.getHorizontalPixelRange();
      const gridWidth = gridPixelRange.right;
      // if grid width is greater than sum total of all column width's
      // we need to call sizeColumnsToFit so that columns fill entire width of grid
      if (gridWidth > numberOfColumns * columnWidth) {
        params.api.sizeColumnsToFit();
      }
    } else {
      params.columnApi.autoSizeAllColumns();
    }
  }
}

function onRowClicked(e: RowClickedEvent) {
  emit('row-clicked', e.data);
}

watch(
  () => page.value,
  (newPageValue) => {
    if (!newPageValue) return;
    gridApi.value?.paginationGoToPage(newPageValue - 1);
  },
);
watch(
  () => searchQuery,
  (query) => {
    if (!useSearch) return;
    gridApi.value?.setQuickFilter(query);
    displayedRowCount.value = gridApi.value?.getDisplayedRowCount() || 0;
  },
);
</script>

<style lang="pcss">
.bitts-table {
  /* main styles */
  .ag-root-wrapper {
    @apply rounded-2xl border-neutral-border;
  }
  &.with-header .ag-root-wrapper {
    @apply rounded-t-none;
  }
  .ag-header {
    @apply bg-white border-b border-neutral-border border-solid;
  }
  .ag-row {
    @apply border-l-0 border-r-0 border-t-0 bg-white border-neutral-border;
  }
  .ag-row-last {
    @apply border-b-0;
  }
  .ag-header {
    min-height: 40px !important;
    height: 40px !important;
    border-bottom: none !important;
  }
  .ag-header-row {
    height: 40px !important;
  }
  .ag-cell,
  .ag-header-cell {
    @apply px-16;
  }

  .ag-cell {
    @apply flex items-center overflow-x-hidden overflow-y-hidden;
  }

  .ag-header-cell {
    @apply leading-6;
  }
  .ag-paging-panel {
    @apply border-t-0;
  }
  .ag-ltr .ag-header-cell-resize {
    @apply right-[-5px];
  }
  /* This class adds some weird spacing above the pagination section, removing it for now */
  .ag-center-cols-clipper {
    min-height: 0 !important;
  }
  /* bordered table styles */
  &.bordered {
    .ag-cell,
    .ag-header-cell {
      @apply border-0 border-r border-solid border-neutral-background-disabled;
    }
  }
  /* custom pagination styles */
  &.pagination {
    .ag-root-wrapper {
      @apply border-b-0 rounded-bl-none rounded-br-none;
    }
  }
  .bitts-table__pagination-container {
    @apply flex items-center bg-white p-16 border rounded-b-2xl
    border-neutral-border border-t-0 shadow-component justify-end;
  }

  .bitts-table__column-header {
    @apply flex items-center w-full;
  }
  [aria-sort='ascending'] .c-bitts-table__sort-toggle,
  .manual-sort-ascending .c-bitts-table__sort-toggle {
    @apply rotate-180;
  }
  .bitts-table__cta-wrapper {
    @apply flex items-center bg-white py-12 px-16 w-full overflow-auto
    rounded-t-2xl border-x border-t border-neutral-border justify-between shadow-component;
  }

  .ag-row {
    &.ag-row-selected::before {
      @apply bg-white;
    }
  }

  /* Zebra pattern */
  .ag-row-odd,
  .ag-row-even {
    @apply border-none;
  }

  .ag-row-even {
    @apply bg-neutral-background-weak;
    &.ag-row-selected::before {
      @apply bg-neutral-background-weak rounded-16;
    }
  }

  /* Checkboxes... */
  .ag-input-wrapper.ag-checkbox-input-wrapper {
    &:focus-within {
      @apply shadow-none;
    }
  }
  .ag-theme-alpine.ag-checked.ag-checkbox-input-wrapper,
  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper {
    @apply border-none cursor-pointer rounded-3;
    box-shadow: theme(colors.neutral.accent) inset 0px 0px 0px 1px;
    &:hover {
      box-shadow: theme(colors.violet.700) inset 0px 0px 0px 1px;
    }
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper:hover {
    @apply cursor-pointer;
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
    @apply bg-secondary-accent mt-4 ml-4 rounded-bts-xs;
    content: '';
    height: 8px;
    width: 8px;
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    @apply text-secondary-accent;
  }
  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper::after {
    content: '';
  }
}
</style>
